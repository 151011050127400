import React, { useState, useEffect } from 'react';
import { ref, set, get, push, update, onValue } from 'firebase/database';
import { database } from './firebase';
import { useNavigate } from 'react-router-dom'; // Для перехода между страницами

function FightPage({ uid }) {
  const [isSearching, setIsSearching] = useState(false);
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [lobbyId, setLobbyId] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const [playersInLobby, setPlayersInLobby] = useState(0);
  const [isGameStarted, setIsGameStarted] = useState(false);
  let isCancelled = false;

  const navigate = useNavigate(); // Для навигации на другую страницу

  useEffect(() => {
    let interval;
    if (isSearching) {
      interval = setInterval(() => {
        setSecondsElapsed((prev) => prev + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isSearching]);

  const handleSearchOpponent = async () => {
    if (!uid) {
      console.error('UID не передан. Поиск невозможен.');
      return;
    }
    setIsSearching(true);
    setSecondsElapsed(0);
    console.log('Начат поиск соперника...');

    const lobbyRef = ref(database, 'lobbies');
    try {
      const snapshot = await get(lobbyRef);
      if (isCancelled) return;

      const lobbies = snapshot.val() || {};
      let joinedLobbyId = null;

      for (let id in lobbies) {
        const lobby = lobbies[id];
        if (lobby.players && lobby.players.length === 1 && lobby.status === 'waiting') {
          await set(ref(database, `lobbies/${id}/players/${lobby.players.length}`), uid);
          joinedLobbyId = id;
          setPlayersInLobby(2);
          console.log('Присоединился к лобби:', id);
          break;
        }
      }

      if (!joinedLobbyId) {
        const newLobbyRef = push(lobbyRef);
        joinedLobbyId = newLobbyRef.key;
        await set(newLobbyRef, { players: [uid], status: 'waiting' });
        setPlayersInLobby(1);
        console.log('Создано новое лобби:', joinedLobbyId);
      }

      setLobbyId(joinedLobbyId);
    } catch (error) {
      console.error('Ошибка поиска лобби:', error);
    }
  };

  const handleCancelSearch = async () => {
    setIsSearching(false);
    setSecondsElapsed(0);
    isCancelled = true;

    if (lobbyId) {
      const lobbyRef = ref(database, `lobbies/${lobbyId}`);
      try {
        const snapshot = await get(lobbyRef);
        const lobby = snapshot.val();

        if (lobby && lobby.players && lobby.players.length === 1 && lobby.players[0] === uid) {
          await set(lobbyRef, null);
          console.log('Лобби удалено:', lobbyId);
        } else if (lobby && lobby.players) {
          const updatedPlayers = lobby.players.filter((player) => player !== uid);
          await update(lobbyRef, { players: updatedPlayers });
          console.log('Вышел из лобби:', lobbyId);
        }
      } catch (error) {
        console.error('Ошибка отмены поиска:', error);
      }
    }

    setLobbyId(null);
    setPlayersInLobby(0);
  };

  useEffect(() => {
    if (lobbyId) {
      const lobbyRef = ref(database, `lobbies/${lobbyId}`);
      const unsubscribe = onValue(lobbyRef, (snapshot) => {
        const lobby = snapshot.val();
        console.log('Полученные данные лобби:', lobby);

        if (lobby) {
          const playersCount = lobby.players ? lobby.players.length : 0;
          setPlayersInLobby(playersCount);
          setCountdown(lobby.countdown);

          if (playersCount === 2 && lobby.countdown === undefined) {
            updateCountdownInLobby(5);
          }

          if (lobby.countdown === 0) {
            setIsGameStarted(true);
            const currentPlayerUid = uid; // UID текущего игрока

            if (lobby.players.includes(currentPlayerUid)) {
              // Выполняем редирект на страницу игры в той же вкладке
              navigate(`/Game?start=${currentPlayerUid}&lobby=${lobbyId}`);
            }
          }

          // Обновляем статус лобби на "Play", когда начинается игра
          if (playersCount === 2 && lobby.countdown === 0) {
            updateLobbyStatusToPlay(lobbyId);
          }
        }
      });
      return () => unsubscribe();
    }
  }, [lobbyId, countdown, uid, navigate]);

  const updateCountdownInLobby = (newCountdown) => {
    if (lobbyId) {
      const lobbyRef = ref(database, `lobbies/${lobbyId}`);
      update(lobbyRef, { countdown: newCountdown });
      console.log('Обновлен countdown в Firebase:', newCountdown);
    }
  };

  const updateLobbyStatusToPlay = (lobbyId) => {
    if (lobbyId) {
      const lobbyRef = ref(database, `lobbies/${lobbyId}`);
      update(lobbyRef, { status: 'Play' })
        .then(() => {
          console.log('Статус лобби обновлен на "Play"');
        })
        .catch((error) => {
          console.error('Ошибка при обновлении статуса лобби:', error);
        });
    }
  };

  useEffect(() => {
    let countdownInterval;
    if (countdown !== null && countdown > 0) {
      countdownInterval = setInterval(() => {
        const newCountdown = countdown - 1;
        setCountdown(newCountdown);
        updateCountdownInLobby(newCountdown);
      }, 1000);
    } else if (countdown === 0) {
      clearInterval(countdownInterval);
      console.log('Начинается игра!');
    }
    return () => clearInterval(countdownInterval);
  }, [countdown]);

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div>
      {isSearching && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 2000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ textAlign: 'center', color: 'white' }}>
            <div
              style={{
                border: '4px solid #f3f3f3',
                borderTop: '4px solid #007BFF',
                borderRadius: '50%',
                width: '70px',
                height: '70px',
                margin: '0 auto',
                animation: 'spin 1s linear infinite',
              }}
            ></div>
            <p style={{ marginTop: '10px', fontSize: '18px' }}>
              {formatTime(secondsElapsed)}
            </p>
            <button
              onClick={handleCancelSearch}
              style={{
                padding: '10px 20px',
                fontSize: '16px',
                borderRadius: '8px',
                border: 'none',
                backgroundColor: '#373A40',
                color: '#DC5F00',
                cursor: 'pointer',
                marginTop: '20px',
              }}
            >
              Отменить поиск
            </button>
          </div>
        </div>
      )}

      {playersInLobby === 2 && countdown !== null && countdown > 0 && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 2000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '48px',
            fontWeight: 'bold',
          }}
        >
          <p>Игра начнется через {countdown}</p>
        </div>
      )}

      <div
        style={{
          textAlign: 'center',
          marginTop: '60%',
          pointerEvents: isSearching || playersInLobby === 2 ? 'none' : 'auto',
          opacity: isSearching || playersInLobby === 2 ? 0.5 : 1,
        }}
      >
        {!isSearching && playersInLobby < 2 && (
          <button
            onClick={handleSearchOpponent}
            style={{
              padding: '15px 30px',
              fontSize: '18px',
              borderRadius: '10px',
              border: 'none',
              backgroundColor: '#373A40',
              color: '#DC5F00',
              cursor: 'pointer',
            }}
          >
            Поиск соперника
          </button>
        )}
      </div>

      <style>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
}

export default FightPage;
