import React, { useState, useEffect } from 'react';
import { db, database } from './firebase';
import { ref, get } from 'firebase/database';
import { doc, getDoc } from 'firebase/firestore';

function Market({ setError }) {
  const [allCards, setAllCards] = useState([]);

  useEffect(() => {
    console.log('Market компонент смонтирован');
    fetchMarketCards();
  }, []);

  const fetchMarketCards = async () => {
    try {
      console.log('Начинается загрузка карт с рынка');
      const marketRef = ref(database, 'market');
      const marketSnapshot = await get(marketRef);

      if (marketSnapshot.exists()) {
        const marketData = marketSnapshot.val();
        console.log('Данные с рынка:', marketData);

        const cardsData = await Promise.all(
          Object.values(marketData).map(async (card) => {
            const cardId = card.cardId;
            if (cardId) {
              console.log('Получение данных карты:', cardId);
              const cardDocRef = doc(db, 'cards', cardId);
              const cardDoc = await getDoc(cardDocRef);
              const cardDetails = cardDoc.exists() ? cardDoc.data() : {};

              return {
                card_id: cardId,
                name: cardDetails.name || 'Без имени',
                image_url: cardDetails.image_url || '',
                description: cardDetails.description || '',
                price: card.price || 0,
                quantity: card.quantity || 0,
              };
            }
            return null;
          })
        );

        const filteredCards = cardsData.filter((card) => card !== null);
        console.log('Успешно загружены карты с рынка:', filteredCards);
        setAllCards(filteredCards);
      } else {
        console.warn('На рынке нет карт');
        setError('На рынке нет карт.');
      }
    } catch (error) {
      console.error('Ошибка при загрузке рынка:', error);
      setError('Не удалось загрузить карты с рынка.');
    }
  };

  return (
    <div>
      <h2>Карты на рынке</h2>
      <div className="grid">
        {allCards.length === 0 ? (
          <div>Карты для покупки не найдены.</div>
        ) : (
          allCards.map((card) => (
            <div
              key={card.card_id}
              className={`card ${card.quantity <= 0 ? 'inactive-card' : ''}`}
            >
              <img src={card.image_url} alt={card.name} />
              <div>{card.name}</div>
              <div>Цена: {card.price} ₽</div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Market;
