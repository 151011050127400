import React from 'react';
import './Profile.css';

function Profile({ uid, name, email, avatarUrl }) {
  return (
    <div className="profile-container">
      <div className="profile-header">
        <img src={avatarUrl} alt="Avatar" className="profile-avatar" />
        <div className="profile-info">
          <h1 className="profile-name">{name}</h1>
          <p className="profile-email">{email}</p>
        </div>
      </div>
      <div className="profile-details">
        <p className="profile-uid">UID: {uid}</p>
        <button className="profile-edit-btn">Редактировать</button>
      </div>
    </div>
  );
}

export default Profile;
