import React, { useEffect, useState } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, database, databaseRef, set } from './firebase'; 
import { push } from 'firebase/database'; 
import './Collection.css';

function Collection({ uid }) {
  const [playerCards, setPlayerCards] = useState([]);
  const [deck, setDeck] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null); 
  const [price, setPrice] = useState('');
  const [finalPrice, setFinalPrice] = useState('');
  const [error, setError] = useState(null); 
  const [isSaleModalOpen, setIsSaleModalOpen] = useState(false); 

  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        const playerDocRef = doc(db, 'users', uid);
        const playerDoc = await getDoc(playerDocRef);
        const playerData = playerDoc.exists() ? playerDoc.data() : {};

        // Загружаем карты игрока
        if (playerData.cards && Array.isArray(playerData.cards)) {
          const playerCardDetails = await Promise.all(
            playerData.cards.map(async (card) => {
              const cardId = card.card_id;
              if (cardId) {
                const cardDocRef = doc(db, 'cards', cardId);
                const cardDoc = await getDoc(cardDocRef);
                const cardData = cardDoc.exists() ? cardDoc.data() : {};

                return {
                  id: cardId,
                  ...cardData,
                  random_value: card.random_value || 0, 
                };
              }
              return null;
            })
          );
          setPlayerCards(playerCardDetails.filter((card) => card !== null));
        } else {
          setPlayerCards([]);
        }

        // Загружаем колоду игрока
        if (playerData.deck && Array.isArray(playerData.deck)) {
          setDeck(playerData.deck);
        }
      } catch (error) {
        console.error('Ошибка при загрузке данных игрока:', error);
        setError('Не удалось загрузить данные игрока');
      }
    };

    fetchPlayerData();
  }, [uid]);

  const saveDeckToFirestore = async (updatedDeck) => {
    try {
      const playerDocRef = doc(db, 'users', uid);
      await updateDoc(playerDocRef, { deck: updatedDeck });
      console.log('Колода успешно сохранена в Firestore:', updatedDeck);
    } catch (error) {
      console.error('Ошибка при сохранении колоды в Firestore:', error);
      setError('Не удалось сохранить колоду.');
    }
  };

  const handleAddToDeck = (event, card) => {
    event.stopPropagation();
    if (deck.length < 20 && !deck.some((deckCard) => deckCard.id === card.id)) {
      setDeck((prevDeck) => {
        const updatedDeck = [...prevDeck, card];
        saveDeckToFirestore(updatedDeck); // Сохраняем колоду в Firestore
        return updatedDeck;
      });
    } else {
      console.log('Карта уже в колоде или лимит достигнут.');
    }
  };
  
  const handleRemoveFromDeck = (card) => {
    setDeck((prevDeck) => {
      const updatedDeck = prevDeck.filter((deckCard) => deckCard.id !== card.id);
      saveDeckToFirestore(updatedDeck); // Сохраняем колоду в Firestore
      return updatedDeck;
    });
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleCloseOverlay = () => {
    setSelectedCard(null);
    setIsSaleModalOpen(false);
  };

  const handleSellCard = () => {
    setIsSaleModalOpen(true);
  };

  const handlePriceBlur = () => {
    if (price && !isNaN(price)) {
      const finalPriceWithCommission = (price * 0.95).toFixed(2);
      setFinalPrice(finalPriceWithCommission);
    }
  };

  const addCardToMarket = async () => {
    try {
      const marketRef = databaseRef(database, 'market'); 
      const newCardRef = push(marketRef);

      await set(newCardRef, {
        cardId: selectedCard.id,
        random_value: selectedCard.random_value, 
        seller: uid,
        price: price, 
        finalPrice: finalPrice, 
      });
    } catch (error) {
      console.error('Ошибка при добавлении карты в рынок:', error);
      setError('Не удалось добавить карту на рынок');
    }
  };

  const handleConfirmSale = async () => {
    try {
      if (selectedCard && price > 0) {
        const playerDocRef = doc(db, 'users', uid);
        const playerDoc = await getDoc(playerDocRef);
        const playerData = playerDoc.exists() ? playerDoc.data() : {};
        
        await addCardToMarket();

        const updatedCards = playerData.cards.filter(card => card.card_id !== selectedCard.id);
        await updateDoc(playerDocRef, { cards: updatedCards });
        
        setPlayerCards(updatedCards); 
        setSelectedCard(null);
        setPrice('');
        setFinalPrice('');
        setIsSaleModalOpen(false);
      }
    } catch (error) {
      console.error('Ошибка при продаже карты:', error);
      setError('Не удалось продать карту');
    }
  };

  const handleCancelSale = () => {
    setPrice('');
    setFinalPrice('');
    setIsSaleModalOpen(false);
  };

  return (
    <div className="collection-container">
      

      {error && <div className="error-message">{error}</div>}

      <h2>Колода</h2>
      {deck.length === 0 ? (
        <div>Ваша колода пуста. Добавьте карты из инвентаря.</div>
      ) : (
        <div className="grid">
          {deck.map((card, index) => (
            <div key={`${card.id}-${index}`} className="card">
              <img src={card.image_url} alt={card.name} />
              <div>{card.name}</div>
              <div>Редкость: {card.random_value.toFixed(9)}</div>
              <button onClick={() => handleRemoveFromDeck(card)}>Удалить из колоды</button>
            </div>
          ))}
        </div>
      )}
      <h1>Ваш инвентарь</h1>
      {playerCards.length === 0 ? (
        <div className="empty-inventory">Ваш инвентарь пуст.</div>
      ) : (
        <div className="grid">
          {playerCards.map((card, index) => (
            <div key={`${card.id}-${index}`} className="card" onClick={() => handleCardClick(card)}>
              <img src={card.image_url} alt={card.name} />
              <div>{card.name}</div>
              <div>Редкость: {card.random_value.toFixed(9)}</div>
              <button onClick={(e) => handleAddToDeck(e, card)}>Добавить в колоду</button>
            </div>
          ))}
        </div>
      )}

      {selectedCard && !isSaleModalOpen && (
        <div className="overlay">
          <div className="overlay-content">
            <h2>{selectedCard.name}</h2>
            <img src={selectedCard.image_url} alt={selectedCard.name} className="overlay-image" />
            <p>Редкость: {selectedCard.random_value.toFixed(9)}</p>
            <div className="overlay-buttons">
              <button onClick={handleSellCard}>Продать карту</button>
              <button onClick={handleCloseOverlay}>Закрыть</button>
            </div>
          </div>
        </div>
      )}

      {isSaleModalOpen && selectedCard && (
        <div className="overlay">
          <div className="overlay-content">
            <h2>Выставить карту на продажу</h2>
            <img src={selectedCard.image_url} alt={selectedCard.name} className="overlay-image" />
            <p>Редкость: {selectedCard.random_value.toFixed(9)}</p>
            <div>
              <label>Цена, которую заплатят: </label>
              <input 
                type="number" 
                value={price} 
                onChange={(e) => setPrice(e.target.value)} 
                onBlur={handlePriceBlur} 
                placeholder="Введите цену" 
              />
            </div>
            {finalPrice && (
              <div>
                <p>Цена, которую получит продавец: {finalPrice} (5% комиссия)</p>
              </div>
            )}
            <div className="overlay-buttons">
              <button onClick={handleConfirmSale}>Подтвердить продажу</button>
              <button onClick={handleCancelSale}>Отменить</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Collection;
