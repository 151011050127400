// TurnManager.js
import React, { useState } from 'react';

const TurnManager = ({ currentPlayer, setCurrentPlayer }) => {
  const handleEndTurn = () => {
    // Логика для завершения хода и перехода к следующему игроку
    setCurrentPlayer(currentPlayer === 'player1' ? 'player2' : 'player1');
  };

  return (
    <div style={styles.turnManager}>
      <p>Текущий игрок: {currentPlayer === 'player1' ? 'Игрок 1' : 'Игрок 2'}</p>
      <button onClick={handleEndTurn} style={styles.endTurnButton}>Завершить ход</button>
    </div>
  );
};

// Стиль для кнопки завершения хода
const styles = {
  turnManager: {
    position: 'absolute',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#333',
    color: '#fff',
    padding: '10px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  endTurnButton: {
    marginTop: '10px',
    backgroundColor: '#f44336',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
  }
};

export default TurnManager;
