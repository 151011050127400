import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { collection, getDocs, doc, getDoc, updateDoc,arrayUnion } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Market from './Market';  // Импортируем компонент Market
import './ShopPage.css';
function ShopPage({ uid }) {
  const [activeTab, setActiveTab] = useState('shop'); // Управление вкладками
  const [selectedCard, setSelectedCard] = useState(null);
  const [isConfirmingPurchase, setIsConfirmingPurchase] = useState(false);
  const [error, setError] = useState(null);
  const [allCards, setAllCards] = useState([]); // Карты для магазина
  const navigate = useNavigate(); // Для навигации

  useEffect(() => {
    if (activeTab === 'shop') {
      fetchCards();
    }
  }, [activeTab]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const fetchCards = async () => {
    try {
      const shopCardsCollection = collection(db, 'shop');
      const shopSnapshot = await getDocs(shopCardsCollection);

      if (shopSnapshot.empty) {
        setError('На рынке нет карт.');
        return;
      }

      const cardsData = await Promise.all(
        shopSnapshot.docs.map(async (docSnapshot) => {
          const cardData = docSnapshot.data();
          const cardId = docSnapshot.id;

          if (cardId) {
            const cardDocRef = doc(db, 'cards', cardId);
            const cardDoc = await getDoc(cardDocRef);
            const cardDetails = cardDoc.exists() ? cardDoc.data() : {};

            return {
              card_id: cardId,
              name: cardDetails.name || 'Без имени',
              image_url: cardDetails.image_url || '',
              description: cardDetails.description || '',
              price: cardData.price || 0,
              quantity: cardData.quantity || 0,
              total_quantity: cardData.total_quantity || 0,
            };
          }
          return null;
        })
      );

      const filteredCards = cardsData.filter((card) => card !== null);
      setAllCards(filteredCards);
    } catch (error) {
      setError('Не удалось загрузить карты.');
    }
  };

  const handleBuyCard = async () => {
    if (!selectedCard || selectedCard.quantity <= 0) {
      setError('Карта недоступна для покупки.');
      return;
    }

    try {
      // Проверяем, что card_id существует
      if (!selectedCard.card_id) {
        setError('Ошибка: Карта не имеет корректного ID.');
        return;
      }

      const randomFloat = parseFloat(Math.random().toFixed(9));

      // Уменьшаем количество карты в коллекции shop
      const cardRef = doc(db, 'shop', selectedCard.card_id);
      await updateDoc(cardRef, { quantity: selectedCard.quantity - 1 });

      // Добавляем карту и random_value в инвентарь пользователя
      const userRef = doc(db, 'users', uid);
      await updateDoc(userRef, {
        cards: arrayUnion({
          card_id: selectedCard.card_id,
          random_value: randomFloat,
        }),
      });

      // Обновляем состояние
      setSelectedCard(null);
      setIsConfirmingPurchase(false);
      setError(null); // Сбрасываем ошибку

      // Перенаправление на страницу инвентаря
      navigate('/collection?start=' + uid); // Убедитесь, что этот путь соответствует вашему маршруту для инвентаря
    } catch (err) {
      setError('Не удалось купить карту.');
    }
  };

  const confirmPurchase = () => {
    setIsConfirmingPurchase(true);
  };

  const cancelPurchase = () => {
    setIsConfirmingPurchase(false);
    setSelectedCard(null);
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  return (
    <div className="shop-page">
      <div className="tabs">
        <button
          className={`tab ${activeTab === 'shop' ? 'active' : ''}`}
          onClick={() => handleTabChange('shop')}
        >
          Магазин
        </button>
        <button
          className={`tab ${activeTab === 'market' ? 'active' : ''}`}
          onClick={() => handleTabChange('market')}
        >
          Рынок
        </button>
      </div>

      <h1>{activeTab === 'shop' ? 'Магазин карт' : 'Рынок карт'}</h1>
      {error && <div className="error-message">{error}</div>}

      {activeTab === 'shop' && (
        <div className="available-cards">
          <h2>Карты для покупки</h2>
          <div className="grid">
            {allCards.length === 0 ? (
              <div className="empty-inventory">Карты для покупки не найдены.</div>
            ) : (
              allCards.map((card) => (
                <div
                  key={card.card_id}
                  className={`card ${card.quantity <= 0 ? 'inactive-card' : ''}`}
                  onClick={() => handleCardClick(card)}
                >
                  <div className="card-quantity-badge">
                    {card.quantity}/{card.total_quantity}
                  </div>
                  <img src={card.image_url} alt={card.name} />
                  <div>{card.name}</div>
                  <div>Цена: {card.price} ₽</div>
                </div>
              ))
            )}
          </div>
        </div>
      )}

      {activeTab === 'market' && <Market setError={setError} />}

      {selectedCard && (
        <div className="overlay">
          <div className="overlay-content">
            <img
              src={selectedCard.image_url}
              alt={selectedCard.name}
              className="overlay-image"
            />
            <h3>{selectedCard.name}</h3>
            <p>{selectedCard.description}</p>
            <div>Цена: {selectedCard.price} ₽</div>
            <div>Доступно: {selectedCard.quantity > 0 ? 'Доступна' : 'Недоступна'}</div>

            {isConfirmingPurchase ? (
              <div>
                <p>Вы уверены, что хотите купить эту карту?</p>
                <button onClick={handleBuyCard}>Подтвердить покупку</button>
                <button onClick={cancelPurchase}>Отмена</button>
              </div>
            ) : (
              <button
                className="buy-btn"
                disabled={selectedCard.quantity <= 0}
                onClick={confirmPurchase}
              >
                Купить
              </button>
            )}

            <button className="close-btn" onClick={() => setSelectedCard(null)}>
              Закрыть
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ShopPage;
