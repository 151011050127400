import { useDrag } from 'react-dnd';
import { useDrop } from 'react-dnd';
import './HandCards.css';

// Компонент для карты игрока
const HandCard = ({ card, index }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'CARD',
    item: { card, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className="player-card"
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <img src={card.image_url} alt={card.name} />
      <p>{card.name}</p>
    </div>
  );
};

// Компонент для поля сражения
const BattleField = ({ onCardDrop }) => {
  console.log('BattleField component rendering');  // Лог при рендере компонента BattleField

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'CARD',
    drop: (item) => {
      console.log('Card dropped on BattleField:', item);  // Лог при сбросе карты
      onCardDrop(item.card);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      className="battle-field"
      style={{
        backgroundColor: isOver ? 'rgba(0, 255, 0, 0.1)' : 'transparent',
      }}
    >
      <p>{isOver ? 'Drop here' : 'Drop your card here'}</p>
      {/* Здесь можно отобразить карты на поле сражения */}
    </div>
  );
};

const HandCards = ({ cards, uid, onCardDrop }) => {
  console.log('HandCards component rendering');  // Лог при рендере компонента HandCards
  return (
    <div className="hand-cards">
      <div className="cards">
        {cards.map((card, index) => (
          <HandCard key={index} card={card} index={index} />
        ))}
      </div>
    </div>
  );
};

const HandSection = ({ cards, uid, onCardDrop }) => {
  console.log('HandSection component rendering');  // Лог при рендере компонента HandSection
  return (
    <div className="hand-section">
      <HandCards cards={cards} uid={uid} onCardDrop={onCardDrop} />
      <BattleField onCardDrop={onCardDrop} />
    </div>
  );
};

const OpponentHandCards = ({ cards, uid }) => {
    return (
      <div className="opponent-hand-cards">
        <div className="opponent-cards">
          {cards.map((card, index) => (
            <div key={index} className="opponent-card">
              <div className="card-back">
                {/* Укажите путь к изображению рубашки карты */}
                <img src="/CARDB.jpg" alt="Card Back" />
                </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
export { HandCards, OpponentHandCards, BattleField };
